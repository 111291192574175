<template>
  <div>
    <pdl-callout kind="default">
      <template #content>
        <span qaid="project-one-unavailable-warning">{{ $t('projectOne.config.unavailable.warning') }}</span>
      </template>
    </pdl-callout>
    <div qaid="p1-saved-config-list" class="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
      <pdl-card
        v-for="config in getRecentConfigObjects"
        :key="config.code"
        :qaid="`p1-saved-config-list__item-${config.code}`"
      >
        <img
          slot="image"
          :src="config.p1ConfigImageUrl"
          :alt="config.p1ConfigDescription"
          :qaid="`p1-saved-config-list__image-${config.code}`"
        />
        <template slot="bodyContent">
          <h2
            class="heading heading--md mb-1"
            :qaid="`p1-saved-config-list__description-${config.code}`"
            v-html="config.p1ConfigDescription"
          />
          <p class="text-md text-gray-60" :qaid="`p1-saved-config-list__code-${config.code}`" v-html="config.code" />

          <trek-button
            :qaid="`p1-saved-config-list__load-button-${config.code}`"
            class="mt-3"
            primary
            @click="goToConfigurationSummary(config.code)"
          >
            <span>{{ $t('projectOne.config.load') }}</span>
          </trek-button>

          <div>
            <trek-button
              :qaid="`p1-saved-config-list__remove-link-${config.code}`"
              class="mt-3"
              button
              icon="delete"
              text
              small
              @click="removeConfig(config.code)"
            >
              <span>{{ $t('text.remove') }}</span>
            </trek-button>
          </div>
        </template>
      </pdl-card>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex';
import storefrontInstance from '@/api/instances/storefront';
import get from 'lodash/get';
import {PdlCard} from '@pedal/pdl-card';
import TrekButton from '@/components/TrekButton';
import {PdlCallout} from '@pedal/pdl-callout';

const URL = '/project-one/api/configurations/';
const RECENT_SHOW_COUNT = 20;

export default {
  name: 'ProjectOneB2cMyAccount',

  components: {PdlCard, TrekButton, PdlCallout},
  data() {
    return {
      configData: [],
    };
  },

  computed: {
    //   Sort the config object according to last modified and show only recent 20 "SAVED" configs
    getRecentConfigObjects() {
      let tempConfigData = this.configData.filter((configItem) => configItem.p1ConfigStatus === 'Saved');

      tempConfigData.sort((a, b) => {
        //   Converting last modified time into milliseconds
        let millisOne = new Date(a.lastModifiedTime).getTime();
        let millisTwo = new Date(b.lastModifiedTime).getTime();

        return millisOne > millisTwo ? -1 : 1;
      });

      tempConfigData.splice(RECENT_SHOW_COUNT);

      return tempConfigData;
    },

    ...mapState('backend', ['encodedContextPath']),
  },

  mounted() {
    storefrontInstance.get(URL).then((response) => {
      this.configData = get(response, 'data.data', []);
    });
  },

  methods: {
    goToConfigurationSummary(code) {
      window.location = `${this.encodedContextPath}/project-one-builder/#/${code}/summary`;
    },

    removeConfig(configurationCode) {
      storefrontInstance.delete(`${URL}${configurationCode}/`).then((response) => {
        //   'newConfigData' is coming null if there not a matchind 'configurationCode' in database.
        let newConfigData = get(response, 'data.data');
        if (newConfigData) this.configData = newConfigData;
      });
    },
  },
};
</script>
