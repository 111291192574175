// Application Shell
import {Shell} from '@/entryPoints/b2c/shell';

import tippy from 'vue-tippy';

import '@/vendor/jquery.mask';
import addMaskingToPostcodeFields from '@/vendor/postcode-fields-format';
import '@/vendor/phone-fields-format.js';
import '@/vendor/acc.removepaymentdetails.js';
import '@/vendor/addresslookup.js';
import deliveryDetails from '@/pages/deliveryDetails';

// Vue Components
import LoginContainer from '@/components/containers/my-account/LoginContainer';
import ForgottenPasswordContainer from '@/components/containers/my-account/ForgottenPasswordContainer';
import ForgotPasswordResetContainer from '@/components/containers/my-account/ForgotPasswordResetContainer';
import EditProfileContainer from '@/components/containers/my-account/EditProfileContainer';
import UpdatePasswordContainer from '@/components/containers/my-account/UpdatePasswordContainer';
import UpdateEmailContainer from '@/components/containers/my-account/UpdateEmailContainer';
import EditLanguageContainer from '@/components/containers/my-account/EditLanguageContainer';
import ProjectOneB2cMyAccount from '@/projectOne/components/external/myAccount/ProjectOneB2cMyAccount';
import ImageComponent from '@/components/ImageComponent';
import ShippingNameForm from '@/components/checkout/forms/ShippingNameForm';
import DeliveryDetails from '@/components/checkout/DeliveryDetails.vue';

// Styling
import '@sassRoot/entryPoints/b2c/my-account.scss';

class MyAccount extends Shell {
  loadPlugins() {
    // Load common plugins from Shell
    super.loadPlugins();
  }

  preLoadInits() {
    super.preLoadInits();

    window.addEventListener('init-vue-dynamic-content', () => {
      const domElements = document.querySelectorAll('[data-vue-dynamic-content]');
      if (!domElements || !domElements.length) {
        return;
      }

      domElements.forEach((el) => {
        new this.vue({
          el: el,
          components: {
            ImageComponent,
          },
        });
      });
    });
  }

  loadTippy() {
    this.vue.use(tippy, {
      animateFill: false,
      arrow: true,
      theme: 'light',
      flipDuration: 0,
      flip: true,
      flipBehavior: ['top', 'right', 'bottom'],
      popperOptions: {
        modifiers: {
          preventOverflow: {
            enabled: true,
          },
        },
      },
    });
  }
}

const myAccount = new MyAccount({
  LoginContainer,
  ForgottenPasswordContainer,
  ForgotPasswordResetContainer,
  EditProfileContainer,
  UpdatePasswordContainer,
  UpdateEmailContainer,
  EditLanguageContainer,
  ProjectOneB2cMyAccount,
  ShippingNameForm,
  DeliveryDetails,
});
myAccount.preLoadInits();
myAccount.initializeVue();

// Legacy foundation initialization

$(window).on('load', () => {
  $(document).foundation();
  myAccount.globalDocumentReadyInits();
  deliveryDetails();
  addMaskingToPostcodeFields($, window.ACC.siteUid);
});

$(window).on('load', () => {
  myAccount.globalOnLoadInits();
});
