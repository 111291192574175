/**
 * Postcode fields formatting library.
 */
import {PostcodeFormats} from '@/constants/postcode-fields-config';
const formatOptions = {
  translation: {
    A: {pattern: /[a-zA-Z]/},
  },
  onKeyPress: function (cep, event, currentField, options) {
    const upperCase = currentField.val().toUpperCase();
    currentField.val(upperCase);
  },
};

const addMaskingToPostcodeFields = ($, locale) => {
  const postCodeFormat = PostcodeFormats[locale] ?? $('.postcode-field').attr('data-format');
  if (postCodeFormat) {
    $('.postcode-field').mask(postCodeFormat, formatOptions);
  }
};

(function ($) {
  addMaskingToPostcodeFields($);
})(jQuery);

export default addMaskingToPostcodeFields;
